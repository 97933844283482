const baseURL = '/srm-oem-goods-service/'// 与后端微服务名对应

export default {
  purchaseOrderListUrl: baseURL + 'purchase-order/list', // 待接单列表
  awaitShipmentsLisUrl: baseURL + 'purchase-order/await-shipments-list', // 待发货单列表
  receiveOrderUrl: baseURL + 'purchase-order/receive', // 接单
  pendingDeliverStaticUrl: baseURL + 'purchase-order/awaitshipment-statistics', // 待发货单列表统计
  productionOrderListUrl: baseURL + 'purchase-order/production-order-list', // 生产订单列表
  productionOrderStaticUrl: baseURL + 'purchase-order/production-order-statistics', // 生产订单列表统计
  purchaseOrderDetailUrl: baseURL + 'purchase-order/detail', // 生产订单详情
  purchaseOrderDetailExportUrl: baseURL + 'purchase-order/detail/export', // 导出生产订单详情
  deliveryConfirmListUrl: baseURL + 'delivery/delivery-confirm-list', // 确认发货列表
  logisticsCompanyUrl: baseURL + 'delivery/logistics-company', // 获取物流商信息
  deliveryUrl: baseURL + 'delivery', // 发货
  deliveryListUrl: baseURL + 'delivery/list', // 发货单列表
  genPreInvoiceBatchNoUrl: baseURL + 'delivery/gen-pre-invoice-batch-no', // 批量导出批次号、打印批次号
  deliverDetailUrl: baseURL + 'delivery/detail', // 发货详情
  deliverPrintUrl: baseURL + 'delivery/print', // 打印发货单
  deliverBatchPrintUrl: baseURL + 'delivery/batch-print', // 批量打印发货单
  logisticsInformationUrl: baseURL + 'delivery/logistics-information', // 获取物流信息
  deliveryPackageListUrl: baseURL + 'delivery-package/list', // 装箱单列表
  deliveryPackageItemUrl: baseURL + 'delivery-package/package-item', // 装箱单明细
  deliveryPackagePrintUrl: baseURL + 'delivery-package/package-print', // 装箱单打印信息
  designBom: baseURL + 'rpc/plm/design/bom', // 根据 sku 查询Bom物料信息
  designCraft: baseURL + 'rpc/plm/design/craft', // 大货工艺单
  designCraftPrint: baseURL + 'rpc/plm/design/craft/print', // 根据设计款号和版本查询工艺单
  designPattern: baseURL + 'rpc/plm/design/pattern', // 根据设计款号和版本查询纸样
  designSize: baseURL + 'rpc/plm/design/size', // 根据设计款号和版本查询尺寸表
  awaitShipmentsList: baseURL + 'supplement/await-shipments-list', // 补发-待发货列表
  awaitShipmentsListExport: baseURL + 'supplement/await-shipments-list/export', // 补发-待发货列表-导出
  supplement: baseURL + 'supplement', // 补发-生成发货单
  basePlateOrderList: baseURL + 'purchase-order/base-plate-order-list', // 底板订单列表
  statisticByPackage: baseURL + 'base-plate/statistic-by-package', // 装箱单统计数据查询
  statisticByPackageDetail: baseURL + 'base-plate/wait-for-receiving-by-package', // 装箱单详情
  defectiveListUrl: baseURL + 'defective/page', // 次品列表
  defectiveLogisticsUrl: baseURL + 'defective/logistics', // 次品列表-查询物流信息
  defectiveStatusUrl: baseURL + 'defective/enum', // 次品列表-处理状态
  priceRecheckApplyUrl: baseURL + 'purchase-price-recheck/apply', // 成本复核-成本复核申请
  priceRecheckListUrl: baseURL + 'purchase-price-recheck/list4Oem', // 成本复核-成本复核查询
  awaitShipmentsList_export: baseURL + 'purchase-order/await-shipments-list/export', // 导出待发货列表
  deleverylistdata_export: baseURL + 'delivery/list/export', // 导出发货单
  packingListData_export: baseURL + 'delivery-package/list/export', // 导出装箱单
  defectivelistData_export: baseURL + 'defective/export', // 导出次品列表
  productionOrderList_export: baseURL + 'purchase-order/production-order-list/export', // 导出生产订单
  qualityReturnsCheckList: baseURL + 'repair/page-wait-deliver', // 库内质检返修
  createDeliverOrder: baseURL + 'repair/deliver', // 生成发货单
  exportRecheckList: baseURL + 'repair/export', // 导出库内质检返修列表
  defectiveRepairPage: baseURL + 'defective/repair/page', // 库内返修次品列表
  getDefectiveRepairDetail: baseURL + 'defective/repair/logistics', // 库内返修次品物流信息
  getDefectiveRepairEnumstatus: baseURL + 'defective/repair/handle-status-enum', // 库内返修次品处理状态
  exportDefectiveRepairEnumstatus: baseURL + 'defective/repair/export',
  createLabelOrder: (id) => `${baseURL}purchase-order/${id}/create-label-order`, // 标签下单
  moreStockCreateLabelOrder: (id) => `${baseURL}purchase-order/${id}/create-more-stock-label-order`, // 多货标签下单
  getMoreStockLabelOrder: (id) => `${baseURL}purchase-order/${id}/get-more-stock-label-order`, // 查询多货标签订单信息
  getSupplierShippingAddress: `${baseURL}supplier-label-shipping-address`, // 查询某个供应商的标签地址信息
  updateSupplierShippingAddress: `${baseURL}supplier-label-shipping-address` // 添加/编辑标签地址信息
}
