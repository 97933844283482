import { render, staticRenderFns } from "./unQualityReturns.vue?vue&type=template&id=cd08f134&scoped=true&"
import script from "./unQualityReturns.vue?vue&type=script&lang=js&"
export * from "./unQualityReturns.vue?vue&type=script&lang=js&"
import style0 from "./unQualityReturns.vue?vue&type=style&index=0&id=cd08f134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd08f134",
  null
  
)

export default component.exports