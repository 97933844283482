
import { get, post, put } from '@shared/http'
import { objValuesHandler } from '@shared/util'
import URL from './oemGoodsUrl'

const OEM_GOODS_API = {
  getPurchaseOrderList (params) {
    return post(URL.purchaseOrderListUrl, objValuesHandler(params))
  },
  getAwaitShipmentsList (params) {
    return post(URL.awaitShipmentsLisUrl, objValuesHandler(params))
  },
  receiveOrder (params) {
    return put(URL.receiveOrderUrl, params)
  },
  getPendingDeliverStatic (params) {
    return post(URL.pendingDeliverStaticUrl, objValuesHandler(params))
  },
  getDeliveryConfirmList (params) {
    return post(URL.deliveryConfirmListUrl, params)
  },
  getLogisticsCompany (params) {
    return get(URL.logisticsCompanyUrl, params)
  },
  doDelivery (params) {
    return post(URL.deliveryUrl, params)
  },
  getDeliveryList (params) {
    return post(URL.deliveryListUrl, objValuesHandler(params))
  },
  // 获取单个批次号
  genPreInvoiceBatchNo (id) {
    return get(URL.genPreInvoiceBatchNoUrl + `/${id}`, {})
  },
  genDeliverDetailInfo (id) {
    return get(URL.deliverDetailUrl + `/${id}`, {})
  },
  genDeliverPrintInfo (id) {
    return get(URL.deliverPrintUrl + `/${id}`, {})
  },
  genDeliverBatchPrintInfo (params) {
    return post(URL.deliverBatchPrintUrl, params)
  },
  genLogisticsInfo (params) {
    return get(URL.logisticsInformationUrl, params)
  },
  getDeliveryPackageList (params) {
    return post(URL.deliveryPackageListUrl, objValuesHandler(params))
  },
  getDeliveryPackageItem (params) {
    return post(URL.deliveryPackageItemUrl, params)
  },
  getDeliveryPackagePrintInfo (params) {
    return post(URL.deliveryPackagePrintUrl, params)
  },
  // 生产订单列表
  getProductionOrderList (params) {
    return post(URL.productionOrderListUrl, objValuesHandler(params))
  },
  // 生产订单列表统计
  getProductionOrderStatic (params) {
    return post(URL.productionOrderStaticUrl, objValuesHandler(params))
  },
  // 生产订单详情
  getProductionOrderDetail (params) {
    return post(URL.purchaseOrderDetailUrl, params)
  },
  // 导出生产订单详情
  exportProductionOrderDetail (params) {
    return get(URL.purchaseOrderDetailExportUrl, params, { responseType: 'blob' })
  },
  designBom (params) {
    return post(URL.designBom, params)
  },
  designCraft (params) {
    return post(URL.designCraft, params)
  },
  designCraftPrint (params) {
    return post(URL.designCraftPrint, params)
  },
  designPattern (params) {
    return post(URL.designPattern, params)
  },
  designSize (params) {
    return post(URL.designSize, params)
  },
  awaitShipmentsList (params) {
    return post(URL.awaitShipmentsList, params)
  },
  // 导出质量管理-待补发列表
  awaitShipmentsListExport (params) {
    return post(URL.awaitShipmentsListExport, params, { responseType: 'blob' })
  },
  supplement (params) {
    return post(URL.supplement, params)
  },
  basePlateOrderList (params) {
    return post(URL.basePlateOrderList, params)
  },
  statisticByPackage (params) {
    return get(URL.statisticByPackage, params)
  },
  statisticByPackageDetail (params) {
    return get(URL.statisticByPackageDetail, params)
  },
  getDefectiveList (params) {
    return post(URL.defectiveListUrl, objValuesHandler(params))
  },
  getDefectiveLogistics (id) {
    return get(`${URL.defectiveLogisticsUrl}/${id}`, {})
  },
  priceRecheckApply (params) {
    return post(URL.priceRecheckApplyUrl, objValuesHandler(params))
  },
  priceRecheckList (params) {
    return post(URL.priceRecheckListUrl, objValuesHandler(params))
  },
  exptawaitshipmentslist (params) {
    return post(URL.awaitShipmentsList_export, objValuesHandler(params))
  },
  exportDeliveryList (params) {
    return post(URL.deleverylistdata_export, objValuesHandler(params))
  },
  exportPackingList (params) {
    return post(URL.packingListData_export, params)
  },
  exportDefectiveList (params) {
    return post(URL.defectivelistData_export, objValuesHandler(params))
  },
  exportProductOrderList (params) {
    return post(URL.productionOrderList_export, objValuesHandler(params))
  },
  // 库内质检返修
  qualityRecheck (params) {
    return post(URL.qualityReturnsCheckList, objValuesHandler(params))
  },
  createDeliverOrder (params) {
    return post(URL.createDeliverOrder, objValuesHandler(params))
  },
  // 导出库内质检返修列表
  awaitRecheckExport (params) {
    return post(URL.exportRecheckList, params, { responseType: 'blob' })
  },
  // 库内返修次品列表
  getDefectiveRepairList (params) {
    return post(URL.defectiveRepairPage, objValuesHandler(params))
  },
  getDefectiveRepairDetail (id) {
    return get(URL.getDefectiveRepairDetail + `/${id}`, {})
  },
  exportDefectiveRepairList (params) {
    return post(URL.exportDefectiveRepairEnumstatus, objValuesHandler(params))
  },
  // 标签下单
  createLabelOrder (id) {
    return put(URL.createLabelOrder(id))
  },
  // 多货标签下单
  moreStockCreateLabelOrder (id, params) {
    return put(URL.moreStockCreateLabelOrder(id), params)
  },
  // 查询多货标签订单信息
  getMoreStockLabelOrder (id) {
    return get(URL.getMoreStockLabelOrder(id))
  },

  // 查询某个供应商的标签地址信息
  getSupplierShippingAddress () {
    return get(URL.getSupplierShippingAddress)
  },
  // 添加/编辑标签地址信息
  updateSupplierShippingAddress (params) {
    return post(URL.updateSupplierShippingAddress, params)
  }
}
export default OEM_GOODS_API
