<template>
  <SlDialog title="物流信息" :visible.sync="dialogVisible" :width="dialogWidth">
    <div v-loading="loading">
      <SlTable
        ref="table"
        align="left"
        :selection="false"
        :border="true"
        :tableData="tableData"
        :columns="columns"
        :operate="false"
        :tooltip="false"
        :isEmbedTable="true"
        maxHeight="320px"
      ></SlTable>
    </div>
    <template v-slot:bottom>
      <el-button @click="dialogVisible = false">关闭</el-button>
    </template>
  </SlDialog>
</template>
<script>
import OemGoodsAPI from '@api/oemGoods'

export default {
  name: 'OemDefectiveLogisticsDialog',
  props: {
    getFuncName: {
      type: String,
      default: 'getDefectiveLogistics'
    }
  },
  data () {
    return {
      loading: false,
      dialogVisible: false,
      dialogWidth: '60%',
      tableData: [],
      columns: [
        {
          prop: 'number',
          label: '序号',
          width: '50'
        },
        {
          prop: 'logisticsCompany',
          label: '物流公司'
        },
        {
          prop: 'trackingNumber',
          label: '物流单号'
        }
      ]
    }
  },
  methods: {
    openDialog ({ id }) {
      this.dialogVisible = true
      this.loading = true
      OemGoodsAPI[this.getFuncName](id).then(res => {
        let data = res.data || []
        this.tableData = data.map((item, index) => {
          return {
            ...item,
            number: index + 1
          }
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss">
</style>
