<template>
  <div>
    <SlListView
      ref="listView"
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div slot="search">
        <!-- 搜索区域search包含搜索和重置按钮 -->
        <SlSearchForm
          ref="searchForm"
          v-model="query"
          :items="searchItems"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>
      </div>
      <div
        style="height: 50px; width: 100%; background: #ffffff; display: flex; justify-content: flex-start; align-items: flex-end; padding: 0 25px;"
      >
        <el-button type="primary" :loading="exportLoading" @click="handleExport">导出</el-button>
        <span class="unqualited_num">本页合计次品: {{ unqualited_num }}</span>
      </div>
      <!-- 表格区域包含分页 -->
      <SlTable
        ref="table"
        :tableData="tableData"
        :columns="columns"
        :selection="false"
        :operate="false"
        :tooltip="false"
        rowKey="id"
      ></SlTable>
    </SlListView>
    <!-- 物流信息对话框 -->
    <OemDefectiveLogisticsDialog ref="logisticsDialog" getFuncName="getDefectiveRepairDetail"></OemDefectiveLogisticsDialog>
  </div>
</template>

<script>
import OemGoodsAPI from '@api/oemGoods'
import OemGoodsUrl from '@api/oemGoods/oemGoodsUrl'
import OemDefectiveLogisticsDialog from './oemDefectiveList/OemDefectiveLogisticsDialog.vue'

export default {
  name: 'DefectiveRepaireList',
  components: {
    OemDefectiveLogisticsDialog
  },
  data () {
    return {
      tableLoading: false,
      exportLoading: false,
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '库内质检单号',
          name: 'qaOrderNumber'
        },
        {
          type: 'input',
          label: '生产订单号',
          name: 'purchaseOrderNumber'
        },
        {
          type: 'input',
          label: '发货单号',
          name: 'deliveryOrderNumber'
        },
        {
          type: 'input',
          label: 'SKU',
          name: 'skuCode'
        },
        {
          type: 'input',
          label: 'UPC',
          name: 'upc'
        },
        {
          type: 'input',
          label: '产品名称',
          name: 'productName'
        },
        {
          type: 'single-select',
          label: '处理状态',
          name: 'handleStatus',
          data: {
            remoteUrl: OemGoodsUrl.getDefectiveRepairEnumstatus,
            params: {}
          }
        },
        {
          type: 'date',
          label: '提交时间',
          name: 'orderTimes',
          data: {
            datetype: 'daterange',
            isBlock: true
          }
        }
      ],
      columns: [
        {
          prop: 'qaOrderNumber',
          label: '库内质检单号'
        },
        {
          prop: 'skuImage',
          label: '产品图片',
          isImg: true,
          data: {
            imgSize: '8rem'
          }
        },
        {
          prop: '',
          label: '产品信息',
          width: '200',
          render: (h, data) => {
            let { row = {} } = data
            let attrs = []
            try {
              attrs = JSON.parse(row.attributesName)
            } catch (error) { }

            attrs = attrs.reduce((acc, cur) => {
              acc += `${acc ? '/' : ''}` + cur.value
              return acc
            }, '')
            return (
              <ul class="align-left">
                <li>SKU:{row.skuCode}</li>
                <li>UPC:{row.upc}</li>
                <li>产品名称:{row.productName}</li>
                <li>销售属性:{attrs}</li>
              </ul>
            )
          }
        },
        {
          prop: 'purchaseOrderNumber',
          label: '生产订单号'
        },
        {
          prop: 'deliveryOrderNumber',
          label: '发货单号'
        },
        {
          prop: 'blemishContentList',
          label: '疵点内容',
          width: '200',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <div class="align-left" style="line-height:1.2">
                {
                  row.blemishContentList && row.blemishContentList.map((content, index) => (
                    <p>{index + 1}、{content}</p>
                  ))
                }
              </div>
            )
          }
        },
        {
          prop: 'blemishImageList',
          label: '疵点图片',
          render: (h, data) => {
            let { row = {} } = data
            if (!row.blemishImageList || row.blemishImageList.length === 0) {
              return <span>-</span>
            }
            return <SlPreviewImages images={row.blemishImageList} ></SlPreviewImages>
          }
        },
        {
          prop: 'defectiveQuantity',
          label: '次品数量'
        },
        {
          prop: 'createTime',
          label: '次品提交时间',
          width: '200'
        },
        {
          prop: 'handleStatusName',
          label: '处理状态'
        },
        {
          prop: 'handleTypeName',
          label: '处理方式'
        },
        {
          prop: '',
          label: '物流信息',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-button type="text" onClick={() => { this.viewVlogistics(row) }}>查看</el-button>
            )
          }
        }
      ],
      unqualited_num: 0
    }
  },
  methods: {
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = { ...this.query, pageIndex, pageSize }
      const { orderTimes } = this.query
      params.createTimeStart = orderTimes[0]
      params.createTimeEnd = orderTimes[1]

      this.tableLoading = true
      OemGoodsAPI.getDefectiveRepairList(params).then(res => {
        let { success, data = {} } = res
        if (success) {
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
          let num = 0
          data.list.forEach(item => {
            num += item.defectiveQuantity
          }, 0)
          this.unqualited_num = num
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    viewVlogistics (row) {
      this.$refs.logisticsDialog.openDialog(row)
    },
    handleExport () {
      this.DeliveryBtnloading = true
      const { orderTimes, deliveryOrderNumber, handleStatus, productName, purchaseOrderNumber, qaOrderNumber, skuCode } = this.query
      const params = { createTimeStart: orderTimes[0], createTimeEnd: orderTimes[1], deliveryOrderNumber, handleStatus, productName, purchaseOrderNumber, qaOrderNumber, skuCode }
      OemGoodsAPI.exportDefectiveRepairList(params).then(res => {
        if (res.success === false) return
        const csvData = new Blob([res], { type: 'text/csv;charset=utf-8;' })
        const csvURL = window.URL.createObjectURL(csvData)
        const tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute('download', '库内返修次品列表.csv')
        tempLink.click()
      }).finally(() => {
        this.DeliveryBtnloading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.unqualited_num {
  display: inline-block;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-left: 12px;
}
</style>
